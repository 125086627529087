@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.listButton {
  height: 50px;
  color: #fff !important;
}

.listButtonActive {
  color: #fff !important;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.15);
}

.doughnut {
  width: 200px;
  display: flex;
  flex-direction: column;
}

.dotColor {
  width: 10px !important;
  height: 10px !important;
  border-radius: 10px !important;
}

.textWhite {
  color: #fff;
}

.rowLegend {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* width: 100%; */
  height: auto;
  margin-top: 20px;
}

.row {
  display: flex;
}

.pickup {
  margin-bottom: 20px;
  color: #D70404;
}

.text_primary {
  color: #D70404 !important;
}

.catergoryBox {
  height: 267px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.categoryInput {}

.categoryInput {
  background-color: #cac8c8;
  border-radius: 10px;
  width: 80%;
  box-shadow: none;
  color: #000;
  height: 50px;
  padding-left: 15px;
  border-radius: 10px;
  border: 1px solid #dfdada;
}

.greyBoxTimer {
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 39px;
  background: #ECECEC;
  border-radius: 5px;
}

.hourHead {
  text-align: left;
  color: #D70404;
  font-size: 12px;
  width: 148px;
}

.timerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.timeBox {
  width: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  background: #FFFFFF;
  border-radius: 3px;
}

.roundWhiteBox {
  width: 20px;
  cursor: pointer;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.borderBlue {
  border: 1px solid #D70404;
  border-radius: 20px;
  padding: 1px;
}

.categoryList {
  max-height: 90px;
  margin-bottom: 10px;
  width: 100%;
  overflow-y: scroll;
}

.loading {
  width: 100% !important;
  align-items: center !important;
  align-self: center !important;
  margin-top: 10px;
  display: flex !important;
  justify-content: center !important
}

.categoryList1 {
  /* max-height: 90px; */
  margin-bottom: 10px;
  width: 100%;
  overflow-y: scroll;
}

.categoryDropdown {
  width: 100%;
  height: 50px;
  border: 1px solid #D70404;
  color: #D70404;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.text_role {
  color: rgba(196, 196, 196, 1) !important;
}

.dividerColor {
  background-color: rgba(255, 255, 255, 0.4) !important;
  width: 80%;
}

.text_white {
  color: #fff !important;
}

.text_secondary {
  color: rgba(10, 160, 244, 1) !important;
}

.text_grey {
  text-transform: uppercase;
  color: rgb(177, 179, 180) !important;
}

.orderUserPhoto {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin: 0px 10px 10px 10px;
}

.item_picture {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 0px 10px 10px 10px;
}

.historyItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;
}

.orderDate {
  height: 50px;
}

.uprCircle {
  width: 30px;
  height: 30px;
  margin-bottom: 15px;
  border-radius: 30px;
  background-color: rgba(197, 208, 221, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.borderGap {
  width: 20%;
  height: 0.1px;
  border: 2px dashed rgba(197, 208, 221, 1);
  margin-left: -10%;
  margin-right: -10%;
  margin-top: 78px;
}

.historycontent {
  text-align: center;
}

.innerCircle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: rgba(197, 208, 221, 1);
}

.innerCircleActive {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #282c34;
}

.c-pointer {
  cursor: pointer;
}

.chartPaper {
  padding: 15px;
  min-height: 180px;
}

.paper {
  padding: 15px;
  width: 100%;
  border-radius: 10px !important;
}

.backArrow {
  width: 30px;
  height: 30px;
  background-color: #282c34;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: -5px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowCenter {
  display: flex;
  align-items: center !important;
}

.greyBox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: 15px;
  background-color: rgba(241, 241, 242, 1);
}

.greenBox {
  width: 20px;
  margin-right: 15px;
  height: 20px;
  border-radius: 3px;
  background-color: rgba(64, 199, 0, 1);
}

.orangeBox {
  width: 20px;
  margin-right: 15px;
  height: 20px;
  border-radius: 3px;
  background-color: rgba(255, 129, 24, 1)
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.header {
  margin-top: 20px !important;
}

.search {
  width: 100%;
}

.search input {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  border-radius: 10px;
  border: none;
}

.form-element {
  position: relative;
}

.search .fa-search {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

.width150 {
  width: 150px !important;
  height: 45px !important;
}

.indicator {
  background-color: #D70404 !important;
}

.tabs {
  color: #D70404 !important;
}

.tabs:focus {
  outline: none;
}

.activeTab {
  color: #D70404 !important;
  box-shadow: 0px 10px 15px 1px #888888;
  background-color: #fff !important;
}

.primaryButton {
  background-color: #D70404 !important;
  color: #fff !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 5px !important;
}

.whitePrimaryButton {
  color: #D70404 !important;
  background-color: #fff !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 5px !important;
}

.outlineButton {
  border: 1px solid #D70404 !important;
  color: #D70404 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-radius: 5px !important;
}

.qrcodeimage {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 15px;
}
.adimage {
  width: 80px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 80px;
  border-radius: 5px;
  object-fit: cover;
  margin-right: 15px;
}

.drawer {
  background-color: #D70404 !important
}

.iconDashboard {
  margin-right: 15px;
}

.drawerClass {
  background-color: #D70404 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

input:focus {
  outline: none;
}

.ProfileImage {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 15px;
}

.head {
  background-color: rgba(245, 247, 250, 1) !important;
  color: #D70404;
  padding: 10px;
  margin-top: 15px;
  border-radius: 8px;
}

.listItem {
  color: #D70404;
  padding: 10px;
  margin-top: 15px;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D70404;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.feedbackUserImage {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 15px;
}

.userDP{
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 15px;
}

.zipModal {
  padding: 20px !important;
}

.issueImg{
  width: 400px;
  object-fit: cover;
  height: 300px;
}

.zipcode {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  border-radius: 10px;
  border: 1px solid #dfdada;

}

.checklistInput {
  width: 100%;
  background-color: transparent;
  color: #fff;
  height: 50px;
  padding-left: 15px;
  border-radius: 10px;
  border: 1px solid #dfdada;
}

.checklistInput::placeholder {
  color: #fff
}

.whiteBoxRound {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blueBox {
  height: 170px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: #D70404;
}

.blueBox1 {
  min-height: 200px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: #D70404;
}

.doughnutCircle {
  width: 120px;
  margin-top: -160px;
  margin-bottom: 50px;
  height: 120px;
  border-radius: 120px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.blueBox1 {
  height: 320px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: #D70404;
}

.bigText {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
}

.headingactive {
  text-align: center;
  color: #D70404;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.whiteText {
  font-size: 14px;
  color: #fff;
}

.mainHeading {
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.whiteGrey {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.qrcodeHead {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  color: #fff;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}